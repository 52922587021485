import { graphql, Link } from "gatsby";
import * as React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout"
import EventEmbedded from "../components/node/EventEmbedded";
import GalleryEmbedded from "../components/node/GalleryEmbedded";
import Paragraph from "../components/paragraph/Paragraph";

const NodeProductionPage = ({data}) => {
  let futureEvents = data.nodeProduction.relationships.field_dates.filter((event) => {
    return event.isFuture;
  });
  let pastEvents = data.nodeProduction.relationships.field_dates.filter((event) => {
    return !event.isFuture;
  });
  let metaTitle = `${data.nodeProduction.title} | ${data.site.siteMetadata.title} | ${data.site.siteMetadata.slogan}`;
  let metaDescription = ``;
  if (data.nodeProduction.metatag) {
    data.nodeProduction.metatag.forEach((metatag) => {
      if (metatag.attributes.name === `description`) {
        metaDescription = metatag.attributes.content;
      }
    });
  }
  return (
    <Layout data={data}>
      <GatsbySeo
        title={metaTitle}
        description={metaDescription}
      />
      <article className="node--production--full">
        <h1>{data.nodeProduction.title}</h1>
        {data.nodeProduction.relationships.field_main_paragraphs.map((paragraph, i) => {
          return <Paragraph key={i} data={data} paragraph={paragraph} />
        })}

        <section className="field field--name-field-dates field--type-entity-reference field--label-above">
          <h2 className="field__label">Termine</h2>
          {futureEvents.length > 0 ? 
            <div className="field__items">
              {futureEvents.map((event, i) => {
                return <EventEmbedded event={event} key={i} hideProduction />
              })}
            </div>
          : ``}
          {pastEvents.length > 0 ? 
            <div className="field__items">
              {pastEvents.map((event, i) => {
                return <EventEmbedded event={event} key={i} hideProduction />
              })}
            </div>
          : ``}
        </section>      

        {data.nodeProduction.relationships.field_galleries.length > 0 ? 
          <section className="field--name-field-galleries">
            <h2 className="field__label">Galerien</h2>
            {data.nodeProduction.relationships.field_galleries.map((gallery, i) => {
              return <GalleryEmbedded images={gallery.relationships.field_images} key={i} />
            })}
          </section>        
          : ``
        }

        {data.nodeProduction.relationships.field_downloads.length > 0 ?
          <section className="field field--name-field-downloads field--type-entity-reference field--label-above field__items">
            <h2 className="field__label">Presse</h2>
            {data.nodeProduction.relationships.field_downloads.map((download, i) => {
              return(
                <div className="field__item" key={i}>
                  <div className="media--document--embedded">
                    <div><a href={download.relationships.field_media_file.localFile.url} target="_blank" rel="noreferrer">{download.name}</a></div>
                  </div>
                </div>
              );
            })}
          </section>
          : ``
        }
        <section className="field field--name-field-roles field--type-string field--label-above field__items">
          <h2 className="field__label">Rollen</h2>
          {data.nodeProduction.field_roles.map((role, i) => {
            return(
              <div className="field__item" key={i}>
                {role}
              </div>
            );
          })}
        </section>
        <section className="field field--name-field-authors field--type-entity-reference field--label-above field__items">
          <h2 className="field__label">Autoren</h2>
          {data.nodeProduction.relationships.field_authors.map((author, i) => {
            return(
              <div className="field__item" key={i}>
                {author.name}
              </div>
            );
          })}
        </section>
        <section className="field field--name-field-production-company field--type-entity-reference field--label-above field__items">
          <h2 className="field__label">Company</h2>
          {data.nodeProduction.relationships.field_production_company.map((company, i) => {
            return(
              <div className="field__item" key={i}>
                {company.name}
              </div>
            );
          })}
        </section>

        <div className="field field--name-field-staff field--type-entity-reference field--label-above field__items">
          <h2 className="field__label">Regie</h2>
          {data.nodeProduction.relationships.field_staff.map((member, i) => {
            let memberOutput = member.name;
            if(member.field_link?.uri) {
              memberOutput = <Link to={member.field_link.uri} target="_blank">{memberOutput}</Link>
            }
            return(
              <div className="field__item" key={i}>
                <div className="taxonomy-term taxonomy-term--linked-title">
                  {memberOutput}
                </div>
              </div>  
            );
          })}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
query NodeProductionQuery($alias: String!) {
  site {
    ...SiteHeader
  }
  nodeProduction(path: {alias: {eq: $alias}}) {
    id
    title
    relationships {
      field_downloads {
        relationships {
          field_media_file {
            localFile {
              url
            }
          }
        }
        name
      }
      field_authors {
        name
      }
      field_staff {
        name
        field_link {
          uri
        }
      }
      field_production_company {
        name
      }
      field_dates {
        field_showtime
        field_showtime_hide_time
        relationships {
          field_location {
            field_address {
              address_line1
              address_line2
              country_code
              locality
              organization
              postal_code
            }
            title
          }
          field_production {
            title
            relationships {
              field_production_company {
                name
                field_company_link {
                  uri
                }
              }
            }
          }
        }
        field_tickets {
          title
          uri
        }
        field_event_attendance_mode
        isFuture
      }
      field_galleries {
        relationships {
          field_images {
            ...GalleryEmbedded
          }
        }
        title
      }
    }
    field_roles
    metatag {
      attributes {
        content
        href
        name
        rel
      }
      tag
    }
    relationships {
      field_main_paragraphs {
        ... on paragraph__html_text {
          ...ParagraphText
        }
        ... on paragraph__call_to_action {
          ...ParagraphCallToAction
        }
        ... on paragraph__panorama_image {
          ...ParagraphPanoramaImage
        }
      }
    }
  }
}
`

export default NodeProductionPage